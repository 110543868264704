<template>
    <div>Esporta Stati Tesserato</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Stati Tesserato", route: "/settings/enrolled-state/index" },
             { title: "Esporta Stati Tesserato" }
         ]);
     }
 };
</script>
